import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { CompanyInfoModel } from "./CompanyInfoModel";

const initialCompany: CompanyInfoModel | null = null;

export const CompanySlice = createSlice<CompanyInfoModel | null, SliceCaseReducers<CompanyInfoModel | null>, string>({
    name: "company",
    initialState: initialCompany,
    reducers: {
        setCompany: (_state: CompanyInfoModel | null, action: PayloadAction<CompanyInfoModel | null>) => action.payload
    }
})