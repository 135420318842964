import React from 'react';
import { AvailableRooms } from '../AvailableRooms';
import { CheckoutPreview } from '../CheckoutPreview';
import Scheduler from '../Scheduler';
import { WeekPicker } from '../WeekPicker';
import styles from './roomCalendar.module.scss';

export const RoomCalendar = () => (
<div className={`${styles.roomCalendar} mx-auto px-3`}>    
    <div>
        <AvailableRooms />
    </div>
    <WeekPicker />
    <Scheduler />
    <CheckoutPreview />
</div>
)