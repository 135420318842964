import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { Preview } from './preview';

export const CheckoutPreview = () => {
    const rooms = useSelector((state:RootState) => state.schedule.Rooms);
    const roomsAr = Object.keys(rooms).map(x => rooms[x]);

    return (roomsAr.length ? <Preview rooms={roomsAr} /> 
    : <></>
)};