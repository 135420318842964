import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduleSlice } from '../../../../../store/slices/ScheduleSlice';
import { RootState } from '../../../../../store/store';
import { DateFormat, StartOfCurrentWeek } from '../../../../../utility/Dates';

export const WeekPicker = () => {
    
    const dispatch = useDispatch();
    const selectedWeekMonday = useSelector((state:RootState) => state.schedule.SelectedWeek);
    const selectedWeekDate = moment(selectedWeekMonday, DateFormat);
    const sun = selectedWeekDate.clone().add(6, "days");
    const isPrevDisabled = selectedWeekDate.clone().add(-7, "days") < StartOfCurrentWeek;

    return (<div>
        <button disabled={isPrevDisabled}
        className="hover:bg-gray-400 text-gray-800 font-bold p-2 mx-1 rounded inline-flex items-center"
        onClick={() => dispatch(ScheduleSlice.actions.prevWeek())}>
            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
        </button>
        <span className="text-xl align-top">{selectedWeekDate.format("DD MMM")} - {sun.format("DD MMM")}</span>
        <button className=" hover:bg-gray-400 text-gray-800 font-bold p-2 mx-1 rounded inline-flex items-center"
        onClick={() => dispatch(ScheduleSlice.actions.nextWeek())}>
            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
        </button>
    </div>)
};