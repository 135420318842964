import { configureStore } from "@reduxjs/toolkit";
import { CheckoutSlice } from "./slices/CheckoutSlice";
import { CheckoutModel } from "./slices/CheckoutSlice/CheckoutModel";
import { CompanySlice } from "./slices/CompanySlice";
import { CompanyInfoModel } from "./slices/CompanySlice/CompanyInfoModel";
import { OrderSlice } from "./slices/OrderSlice";
import { OrderModel } from "./slices/OrderSlice/OrderModel";
import { ScheduleSlice } from "./slices/ScheduleSlice";
import { Schedule } from "./slices/ScheduleSlice/ScheduleModel";
import { UserSlice } from "./slices/UserSlice";
import { UserModel } from "./slices/UserSlice/UserModel";
import { UserAccountModel } from "./slices/UserAccountSlice/UserAccountModel";
import { UserAccountSlice } from "./slices/UserAccountSlice";

export interface RootState {
    company:CompanyInfoModel | null;
    schedule: Schedule;
    user: UserModel;
    checkout: CheckoutModel;
    order: OrderModel;
    userAccount : UserAccountModel;
}
export const Store = configureStore<RootState>({
    reducer: {
        company: CompanySlice.reducer,
        schedule: ScheduleSlice.reducer,
        user: UserSlice.reducer,
        checkout: CheckoutSlice.reducer,
        order: OrderSlice.reducer,
        userAccount: UserAccountSlice.reducer
    }
});