import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Redirect, useHistory } from "react-router-dom";
import { RoomDetails } from './roomsDetails';
import { UserDetailsForm } from './userDetailsForm';
import { ServerConfig } from '../../../../utility/ServerConfig';
import { UserModel } from '../../../../store/slices/UserSlice/UserModel';
import { TFunction } from 'i18next';
import { ErrorResponse, ErrorsKvp } from '../../../../utility/ErrorDetails';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { ScheduleSlice } from '../../../../store/slices/ScheduleSlice';
import { PriceRuleModel } from '../../../../store/slices/CompanySlice/PriceRuleModel';
import { PriceRuleSerice } from '../../../../services/PriceRuleService';
import { LoyaltyDetails } from './loyaltyDetails';

const isUserValid2 = (user: UserModel, numberOfPeople: number | undefined, agreeToRules: boolean, t: TFunction) => {
    let validationObject = {} as ErrorsKvp;
    if (!user.Name) {
        validationObject["ContactName"] = ["ReqiredField"];
    }

    if (!user.Email) {
        validationObject["Email"] = ["ReqiredField"];
    } else if (!/^\S+@\S+\.\S+$/.test(user.Email.trim())) {
        validationObject["Email"] = ["InvalidField"];
    }

    if (!user.Phonenumber) {
        validationObject["Phonenumber"] = ["ReqiredField"];
    }

    if (!numberOfPeople || numberOfPeople <= 0) {
        validationObject["NumberOfPersons"] = ["ReqiredField"];
    }

    if (!agreeToRules) {
        validationObject["AgreeToRules"] = ["ReqiredField"];
    }

    return validationObject;
}

export const BookingDetails = () => {
    const rooms = useSelector((state: RootState) => state.schedule.Rooms);
    const [agreeToRules, setAgreeToRules] = useState(false);
    const { user, company } = useSelector((state: RootState) => state);
    const checkout = useSelector((state: RootState) => state.checkout);
    const { OrderComment } = checkout;
    const priceRules = useSelector((state: RootState) => state.company?.PriceRules) as PriceRuleModel[];
    const dispatch = useDispatch();
    const roomsAr = Object.keys(rooms).map(x => rooms[x]);
    let history = useHistory();
    const [redirectTo, setRedirectTo] = useState("");
    const { t } = useTranslation();
    const [serverError, setServerError] = useState<ErrorResponse>();
    const [numberOfPeople, setNumberOfPeople] = useState<number>();
    const userAccount = useSelector((state: RootState) => state.userAccount);
    const [useLoyaltyPoints, setUseLoyaltyPoints] = useState(false);
    const [companyLoyalties, setCompanyLoyalties] = useState<any>({});


    const onBookClick = () => {
        const validation = isUserValid2(user, numberOfPeople, agreeToRules, t);
        if (Object.keys(validation).length > 0) {
            return setServerError({ errors: validation });
        }
        let RoomSlots = {} as any;
        roomsAr.forEach(x => {
            var selectedSlots = Object.keys(x.SelectedSlots);
            if (selectedSlots.length) {
                RoomSlots[x.RoomSlug] = selectedSlots;
            }
        });
        axios.post(`${ServerConfig.baseUrl}/api/checkout`, {
            RoomSlots: RoomSlots,
            Comment: OrderComment,
            User: user,
            NumberOfPeople: numberOfPeople,
            UseLoyaltyPoints: useLoyaltyPoints
        }).then(response => {
            dispatch(ScheduleSlice.actions.clearSelection());
            setRedirectTo("/order/" + response.data.orderId);
        }).catch(error => {
            if (error.response.data.errors) {
                var errors = { ErrorDetails: [], errors: error.response.data.errors } as ErrorResponse;
                setServerError(errors);
            }
        });
    }

    useEffect(() => {
        axios.get(`${ServerConfig.baseUrl}/api/loyalties`)
            .then(response => {
                setCompanyLoyalties(response.data);
            })
    }, []);

    useEffect(() => {
        if (useLoyaltyPoints && userAccount.IsLoaded && !userAccount.Authenticated) {
            setUseLoyaltyPoints(false);
        }
    }, [userAccount])

    const onEditClick = () => {
        history.goBack();
    }

    const appliedPriceRules = PriceRuleSerice.getCheckoutAdjustments(priceRules, roomsAr, { numberOfPeople: numberOfPeople ?? 0 });

    let total = roomsAr.reduce((prev, room) => {
        prev += room.PricePerSlot * Object.keys(room.SelectedSlots).length;
        if (appliedPriceRules[room.RoomId]) {
            prev += appliedPriceRules[room.RoomId].reduce((p: number, adjustment: any) => p + adjustment.ruleAdjustment, 0);
        }

        return prev;
    }, 0);

    if (appliedPriceRules.globalAdjustment) {
        total += appliedPriceRules.globalAdjustment.reduce((prev: any, adjustment: any) => prev + adjustment.ruleAdjustment, 0);
    }

    let loyaltyPointsAvilableForThisOrder = 0;
    if (userAccount.IsLoaded && userAccount.Authenticated && userAccount.CurrentBalance > 0 && total > 0) {
        loyaltyPointsAvilableForThisOrder = Math.min(total - 1, userAccount.CurrentBalance);
    }

    if (useLoyaltyPoints) {
        total -= loyaltyPointsAvilableForThisOrder;
    }

    return redirectTo ? (<Redirect to={redirectTo} />)
        : (<div>
            <div className={"flex flex-col justify-around sm:flex-row"}>
                <div className="flex-1 px-3">
                    <div>
                        <h1 className="text-2xl">
                            {t("BookingDetails")}
                        </h1>
                    </div>
                    {roomsAr.filter(x => Object.keys(x.SelectedSlots).length > 0).map(x => <RoomDetails key={x.RoomSlug} room={x} appliedPriceRules={appliedPriceRules} />)}
                    {appliedPriceRules.globalAdjustment && <div style={{ marginTop: 20 }}>
                        {appliedPriceRules.globalAdjustment.map((applied: any) => <div className="border border-t-0 border-l-0 border-r-0 flex">
                            <div className="flex-1">
                                <div className="flex">
                                    {applied.rule.Label}
                                    <span className="cursor-pointer ml-1 has-tooltip">
                                        <span className='tooltip rounded shadow-lg p-2 bg-black text-gray-100 whitespace-pre-line' style={{ maxWidth: 200, transform: "translateY(28px)" }}>{applied.rule.Description}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="">
                                {applied.ruleAdjustment} {t("UAH")}
                            </div>
                        </div>)}
                    </div>}

                    {useLoyaltyPoints && <div className="border border-t-0 border-l-0 border-r-0 flex">
                        <div className="flex-1">
                            <div className="flex">
                                {t("RedeemLoyaltyPoints")}
                            </div>
                        </div>
                        <div>
                            -{loyaltyPointsAvilableForThisOrder} {t("UAH")}
                        </div>
                    </div>}

                    <div className="my-3">
                        <div className={"outline-float relative border-2 focus-within:border-black border-gray-400"}>
                            <input type="number" name="NumberOfPersons"
                                placeholder=" "
                                maxLength={50}
                                value={numberOfPeople || ""}
                                onChange={(e) => setNumberOfPeople(Number.parseInt(e.target.value))}
                                className="block p-2 w-full text-lg appearance-none focus:outline-none bg-transparent" />
                            <label htmlFor="name" className="absolute top-0 text-lg bg-white p-2 z-n1 duration-300 origin-0 text-gray-400">
                                {t("NumberOfPersons")}?
                            </label>
                        </div>
                    </div>

                    <LoyaltyDetails
                        total={total}
                        loyaltyPointsAvilableForThisOrder={loyaltyPointsAvilableForThisOrder}
                        useLoyaltyPoints={useLoyaltyPoints}
                        setUseLoyaltyPoints={setUseLoyaltyPoints}
                        companyLoyalties={companyLoyalties} />
                </div>
                <div className="flex-1 px-3 mt-4 sm:mt-0">
                    <UserDetailsForm />
                </div>
            </div>
            <div className="px-3 my-3">
                <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                    <p className="font-bold">{t("BeWarned")}</p>
                    <p>{t("PayTimeWarning", { minutesToPay: company?.MinutesToPayOrder })}</p>
                </div>
            </div>
            <div className="px-3">
                {serverError && <ErrorAlert serverError={serverError} onDismiss={() => setServerError(undefined)} />}
            </div>
            <div className="px-3 pt-5 pb-3">
                <input type="checkbox" id="ackStudioRules" name="AgreeToRules" checked={agreeToRules} onChange={(e) => setAgreeToRules(e.target.checked)} />
                <label htmlFor="ackStudioRules" className="select-none">
                    <span> {t("IAgreeTo")} <a className="hover:text-blue-700 hover:underline text-blue-600" target="_blank" href="/rules">{t("StudioRules")}</a>.</span>
                </label>
            </div>
            <div className="px-3">
                <button className="border hover:font-bold hover:bg-blue-600 hover:text-white p-2" onClick={() => onEditClick()}>{t("Edit")}</button>
                <button className="p-2 border bg-blue-600 text-white font-bold hover:bg-blue-700 ml-2" onClick={() => onBookClick()}>
                    {t("Book")}
                    <span className="bg-indigo-500 border-2 border-black ml-2 p-1 text-white">{total} {t("UAH")}</span>
                </button>
            </div>
        </div>)
}