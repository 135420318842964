import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduleSlice } from '../../../../../store/slices/ScheduleSlice';
import { DaySchedule, SlotStatus } from '../../../../../store/slices/ScheduleSlice/ScheduleModel';
import styles from './dayColumn.module.scss';
import { useRouteMatch } from 'react-router-dom';
import { RootState } from '../../../../../store/store';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { PriceRuleType } from '../../../../../store/enums/PriceRuleType';
import { PriceRuleModel } from '../../../../../store/slices/CompanySlice/PriceRuleModel';
import { WeekDayFlag } from '../../../../../store/enums/WeekDayFlag';
import { PriceRuleSerice } from '../../../../../services/PriceRuleService';

export const DayColumn = ({ day, min, max, date }: { day?: DaySchedule, min: number, max: number, date: moment.Moment }) => {
    const dispatch = useDispatch();
    let { roomSlug } = useRouteMatch<{ roomSlug: string }>().params;
    const { SelectedSlots, PricePerSlot, Schedule, RoomId } = useSelector((state: RootState) => state.schedule.Rooms[roomSlug]);
    const company = useSelector((state: RootState) => state.company);
    const priceRules = useSelector((state: RootState) => state.company?.PriceRules)
        ?.filter(x => x.Type == PriceRuleType.TimeOfADayPriceRule && x.RoomId == RoomId);
    const companyNow = moment().utcOffset(company?.Timezone || 0);
    const busySlots = Schedule.Timeslots;
    const elements = [];
    const datePart = date.format("DDMMYY");
    const { t } = useTranslation();
    const onSlotClick = (hour: number, status: SlotStatus) => {
        return () => {
            if (status === SlotStatus.Open || status === SlotStatus.Selected) {
                dispatch(ScheduleSlice.actions.toggleSlotSelect({ room: roomSlug, slot: datePart + hour.toString() }));
            }
        }
    }
    const isRuleAplicable = (rule: PriceRuleModel, slot: any): boolean => {
        const dayOfWeek = PriceRuleSerice.isoDayOfWeekToWeekDayFlag(date.isoWeekday()); // 0 Sunday, 1 Monday
        return slot.hour >= (rule.StartHour ?? -1)
            && slot.hour < (rule.EndHour ?? 30)
            && (rule.WeekDaysRestriction == WeekDayFlag.None 
                || ((rule.WeekDaysRestriction & dayOfWeek) == dayOfWeek));
    }

    for (let i = min; i < max; ++i) {
        const isSelected = SelectedSlots[datePart + i.toString()];
        let status = SlotStatus.NotAcceptingBooking;
        if (day) { // if day is null/undefined means it has no schedule specified
            status = i < day.Start || i >= day.End || companyNow > date.clone().add(i, "h") ? SlotStatus.NotAcceptingBooking : SlotStatus.Open;

            if (status != SlotStatus.NotAcceptingBooking
                && busySlots.filter(x => x.h === i
                    && x.y === date.year()
                    && x.m === date.month()
                    && x.d === date.date()).length > 0) {
                status = SlotStatus.Busy;
            }
            if (status !== SlotStatus.NotAcceptingBooking
                && status !== SlotStatus.Busy
                && isSelected) {
                status = SlotStatus.Selected
            }
        }
        const slotPrice = priceRules?.reduce((prev, rule) => isRuleAplicable(rule, { hour: i })
            ? prev + rule.PriceDifference
            : prev,
            PricePerSlot) ?? PricePerSlot;

        elements.push(
            <div key={i} title={t(status.toLocaleLowerCase())}
                onClick={onSlotClick(i, status)}
                className={`${styles.timeslot} mx-1 text-center ${styles[status.toLocaleLowerCase()]}`} >
                {(status === SlotStatus.Selected || status === SlotStatus.Open) && <div className={styles.slotInfo}>
                    <div className="text-xs">{("0" + i).slice(-2)}:00 - {("0" + (i + 1)).slice(-2)}:00 </div>
                    <div className="text-xs"> {slotPrice} UAH </div>
                </div>
                }
            </div>
        );
    }

    return (<>{elements}</>)
};