import React from 'react';
import { useSelector } from 'react-redux';
import { LoginForPoints } from '../../../../components/login-for-points';
import { RootState } from '../../../../store/store';
import { AvailableLoyaltyPoints } from './availableLoyaltyPoints';


export const LoyaltyDetails = ({
    total,
    loyaltyPointsAvilableForThisOrder,
    useLoyaltyPoints,
    setUseLoyaltyPoints,
    companyLoyalties
}: {
    total: number,
    loyaltyPointsAvilableForThisOrder: number,
    useLoyaltyPoints: boolean,
    setUseLoyaltyPoints: any,
    companyLoyalties: any
}) => {
    const { company } = useSelector((state: RootState) => state);
    const userAccount = useSelector((state: RootState) => state.userAccount);

    return (
        <>
            {company?.HasActiveLoyalty && total > 0 && <>
                {userAccount.Authenticated && userAccount.IsLoaded ?
                    <>
                        {!!loyaltyPointsAvilableForThisOrder && <AvailableLoyaltyPoints
                            loyaltyPointsAvilableForThisOrder={loyaltyPointsAvilableForThisOrder}
                            useLoyaltyPoints={useLoyaltyPoints}
                            setUseLoyaltyPoints={setUseLoyaltyPoints}
                        />}
                    </>
                    : <LoginForPoints companyLoyalties={companyLoyalties} total={total} />}
            </>}
        </>
    );
}