import moment from "moment";
import { PriceRuleType } from "../../store/enums/PriceRuleType";
import { WeekDayFlag } from "../../store/enums/WeekDayFlag";
import { PriceRuleModel } from "../../store/slices/CompanySlice/PriceRuleModel";
import { Room } from "../../store/slices/ScheduleSlice/ScheduleModel";

class priceRuleService {

    isoDayOfWeekToWeekDayFlag(dayOfWeek:number) {

        switch(dayOfWeek)
        {
            case 1: return WeekDayFlag.Monday;
            case 2: return WeekDayFlag.Tuesday;
            case 3: return WeekDayFlag.Wednesday;
            case 4: return WeekDayFlag.Thursday;
            case 5: return WeekDayFlag.Friday;
            case 6: return WeekDayFlag.Saturday;
            case 7: return WeekDayFlag.Sunday;
        }
        return WeekDayFlag.None;
    }

    isDayOfWeekApplicable(rule: PriceRuleModel, slot: any): boolean {
        return rule.WeekDaysRestriction == WeekDayFlag.None
            || ((rule.WeekDaysRestriction & slot.dayOfWeek) == slot.dayOfWeek);
    }
    isHourOfADayApplicable(rule: PriceRuleModel, slot: any): boolean {
        return slot.hour >= (rule.StartHour ?? -1)
            && slot.hour < (rule.EndHour ?? 30);
    }

    isTimeOfADayRuleApplicable(rule: PriceRuleModel, slot: any): any {
        let isApplicable = this.isHourOfADayApplicable(rule, slot)
            && this.isDayOfWeekApplicable(rule, slot);

        return isApplicable ? {
            PriceDifference: rule.PriceDifference,
            Slot: slot,
        } : null;
    }

    isNumberOfSlotsBookedApplicable(rule: PriceRuleModel, reservedRooms: Room[]): boolean {
        if (rule.RoomId) {
            var room = reservedRooms.find(x => x.RoomId == rule.RoomId);
            if (room) {
                return (Object.keys(room.SelectedSlots).length < rule.LessThenSlots!);
            }

            return false;
        }

        const totalSlotsSelected = reservedRooms.reduce((accum, curr) => accum + Object.keys(curr.SelectedSlots).length, 0);
        return totalSlotsSelected < rule.LessThenSlots!;
    }

    isNumberOfParticipantsApplicable(rule: PriceRuleModel, checkout: {numberOfPeople:number}) {
        let config = [...rule.ParticipantsPriceConfig!];
        config.sort( (a, b) => a.MoreThanParticipant - b.MoreThanParticipant);

        for (let i = config.length -1; i >= 0; --i) {
            if (checkout.numberOfPeople >= config[i].MoreThanParticipant) {
                return config[i];
            }
        }

        return null;
    }

    isHoursBookedPriceRuleApplicable(rule: PriceRuleModel, slot: any, resrvedRooms: Room[]): any {
        let isApplicable = this.isHourOfADayApplicable(rule, slot)
            && this.isDayOfWeekApplicable(rule, slot)
            && this.isNumberOfSlotsBookedApplicable(rule, resrvedRooms);

        return isApplicable ? {
            PriceDifference: rule.PriceDifference,
            Slot: slot,
        } : null;    
    }

    isParticipantPriceRuleApplicable(rule: PriceRuleModel, slot:any, checkout: {numberOfPeople:number}): any {
        let isApplicable = this.isHourOfADayApplicable(rule, slot)
        && this.isDayOfWeekApplicable(rule, slot);
        
        let appliedConfig = isApplicable ? this.isNumberOfParticipantsApplicable(rule, checkout): null;

        return isApplicable && appliedConfig ? {
            PriceDifference: appliedConfig.PriceDifference,
            AppliedConfig: appliedConfig,
            Slot: slot,
        } : null;
    }

    isRuleAplicable(rule: PriceRuleModel, slot: any, resrvedRooms: Room[], checkout: {numberOfPeople:number}): any {
        if (rule.Type == PriceRuleType.TimeOfADayPriceRule) {
            return this.isTimeOfADayRuleApplicable(rule, slot);
        } else if (rule.Type == PriceRuleType.HoursBookedPriceRule) {
            return this.isHoursBookedPriceRuleApplicable(rule, slot, resrvedRooms);
        } else if (rule.Type == PriceRuleType.ParticipantPriceRule) {
            return this.isParticipantPriceRuleApplicable(rule, slot, checkout);
        }

        return null;
    }

    getCheckoutAdjustments(priceRules: PriceRuleModel[], reservedRooms: Room[], checkout: {numberOfPeople:number}) {

        let appliedRules = reservedRooms.map(room => {
            return priceRules?.map(rule => {

                if (room.RoomId == rule.RoomId) {
                    const appliedAdjustments = Object.keys(room.SelectedSlots).map((slot) => {
                        const selectedDate = moment(slot, "DDMMYYHH"); //25112111
                        const dow = this.isoDayOfWeekToWeekDayFlag(selectedDate.isoWeekday());
                        const hour = selectedDate.hour();
                        return this.isRuleAplicable(rule, { hour, dayOfWeek: dow }, reservedRooms, checkout);
                    }).filter(x => !!x);
                    return {
                        appliedAdjustments: appliedAdjustments,
                        rule: rule,
                        ruleAdjustment: appliedAdjustments.reduce((prev, curr) =>  prev + curr.PriceDifference, 0),
                    };
                } else {
                    return null;
                }

            }).filter(x => !!x && x.ruleAdjustment) ?? [];

        }).reduce((prev, curr) => {
            if (curr[0]) {
                prev[curr[0].rule.RoomId!] = curr;
            }

            return prev;
        }, {} as any);

        var globalRules = priceRules?.filter(x => !x.RoomId);
        if (globalRules?.length) {
            appliedRules["globalAdjustment"] = globalRules.map((rule) => {
                const appliedAdjustments = reservedRooms.map((room) => {
                    return Object.keys(room.SelectedSlots)
                        .map((slot) => {
                            const selectedDate = moment(slot, "DDMMYYHH"); //25112111
                            const dow = this.isoDayOfWeekToWeekDayFlag(selectedDate.isoWeekday());
                            const hour = selectedDate.hour();
                            return this.isRuleAplicable(rule, { hour, dayOfWeek: dow }, reservedRooms, checkout);
                        });
                }).flat().filter(x => !!x);

                return {
                    appliedAdjustments: appliedAdjustments,
                    rule: rule,
                    ruleAdjustment: appliedAdjustments.reduce((prev, curr) => prev + curr.PriceDifference, 0),
                }
            }).filter(x=> !!x && x.ruleAdjustment);
        }

        return appliedRules;
    }
}

export const PriceRuleSerice = new priceRuleService();