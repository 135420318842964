import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { DateFormat, StartOfCurrentWeek } from "../../../utility/Dates";
import { Room, Schedule } from "./ScheduleModel";

const initialSchedule:Schedule = {
SelectedWeek: StartOfCurrentWeek.format("DD-MM-YYYY"),
Rooms: {}
};

export const ScheduleSlice = createSlice({
    name: "schedule",
    initialState: initialSchedule,
    reducers: {
        addRoomSchedule: (state:Schedule, action:PayloadAction<Room>) =>  {
            state.Rooms[action.payload.RoomSlug] = {...action.payload, 
                ...{ SelectedSlots: state.Rooms[action.payload.RoomSlug] 
                    ? state.Rooms[action.payload.RoomSlug].SelectedSlots
                    : (action.payload.SelectedSlots ?? {}) } };
            return state;
        },
        nextWeek: (state:Schedule) => {
            state.SelectedWeek = moment(state.SelectedWeek, DateFormat).add(7, "days").format(DateFormat)
        },
        prevWeek: (state:Schedule) => { 
            state.SelectedWeek = moment(state.SelectedWeek, DateFormat).add(-7, "days").format(DateFormat)
        },
        toggleSlotSelect: (state:Schedule, action:PayloadAction<{room:string, slot:string}>) => {
            if(state.Rooms[action.payload.room].SelectedSlots[action.payload.slot]) {
                delete state.Rooms[action.payload.room].SelectedSlots[action.payload.slot]
            } else {
                state.Rooms[action.payload.room].SelectedSlots[action.payload.slot] = true;
            }
        },
        clearSelection: (state:Schedule) => {
            Object.keys(state.Rooms).forEach(key => {
                state.Rooms[key].SelectedSlots = {};
            });
            return state;
        }
        
    }
})