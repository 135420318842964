import React from 'react';
import { useTranslation } from 'react-i18next';

export const PriceAdjustments = ({ appliedPriceRules }: { appliedPriceRules: any[] }) => {
    const { t } = useTranslation();
    return (<>
        {appliedPriceRules.map(applied => <div className="border border-t-0 border-l-0 border-r-0 flex">
            <div className="flex-1">
                <div className="flex">
                    {applied.rule.Label}
                    <span className="cursor-pointer ml-1 has-tooltip">
                        <span className='tooltip rounded shadow-lg p-2 bg-black text-gray-100 whitespace-pre-line'  style={{maxWidth: 200, transform:"translateY(28px)"}}>{applied.rule.Description}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </span>
                </div>
            </div>
            <div className="">
                {applied.ruleAdjustment} {t("UAH")}
            </div>
        </div>)}
    </>)
}