import React, { useEffect } from 'react';
import './App.main.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import BookingPage from './pages/BookingPage';
import NotFoundPage from './pages/NotFoundPage';
import OrderPage from './pages/OrderPage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/store';
import { Footer } from './pages/components/Footer';
import { PublicOfferAgreement } from './pages/PublicOfferAgreement';
import { GA4React } from 'ga-4-react';
import { RentRules } from './pages/RentRules';
import TagManager from 'react-gtm-module';
import axios from 'axios';
import { ServerConfig } from './utility/ServerConfig';
import { UserAccountSlice } from './store/slices/UserAccountSlice';
import { UserAccountModel } from './store/slices/UserAccountSlice/UserAccountModel';
import { UserSlice } from './store/slices/UserSlice';
import { UserModel } from './store/slices/UserSlice/UserModel';

function getFaviconEl() {
  return document.getElementById("favicon") as HTMLLinkElement;
}

function App() {
  const company = useSelector((state: RootState) => state.company);
  useEffect(()=> {
    if(company) {
      const favicon = getFaviconEl();
      favicon.href = company.FaviconUri;
    }
  }, [company]);

  useEffect(() => {
    if(company?.GoogleAnalyticCode) {
      const ga4react = new GA4React(company.GoogleAnalyticCode);
        ga4react.initialize().then((ga4) => {
          ga4.pageview('path');
        },(err) => {
          console.error(err)
        });
    }
  }, [company]);

  useEffect(() => {
    if(company?.GoogleGTag) {
      const tagManagerArgs = {
        gtmId: company?.GoogleGTag
      };
      
      TagManager.initialize(tagManagerArgs);
    }

  }, [company?.GoogleGTag]);

  useEffect(() => {
    document.title = company 
    ? "Planer Online  - " + company.CompanyName
    : "Planer Online";
  }, [company]);

  const dispatch = useDispatch();
  useEffect(() => {
    axios.get(`${ServerConfig.baseUrl}/api/userAccounts/me`)
      .then((response: any) => {
        const { setUserAccount } = UserAccountSlice.actions;
        const { setUser } = UserSlice.actions;

        dispatch(setUserAccount(response.data as UserAccountModel));
        dispatch(setUser(response.data as UserModel));
      });
  }, [dispatch]);

  return (

      <Router>
        <div className="content">
          <Switch>
            <Route path="/public-offer-agreement">
              <PublicOfferAgreement />
            </Route>
            <Route path="/rules">
              <RentRules />
            </Route>
            <Route path="/order/:orderId">
              <OrderPage />
            </Route>
            <Route exact path="/" >
              <Redirect to="/rooms" />
            </Route>
            <Route path="">
              <BookingPage />
            </Route>
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </div>
        <div className="footer"> 
          <Footer />
        </div>
        
      </Router>
  );
}

export default App;
