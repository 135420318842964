import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServerConfig } from '../../utility/ServerConfig';
import { CalendarHeader } from '../BookingPage/components/calendarHeader';

export const PublicOfferAgreement = () => {
    const [pageContent, setPageContent] = useState("");
    const [error, setError] = useState("");
    const { t } = useTranslation();
    
    useEffect(() => {
        axios.get<any>(`${ServerConfig.baseUrl}/api/company/public-offer-agreement`)
            .then(response => {
                setPageContent(response.data.PageHtmlContent);
            })
            .catch(error => {
                setError(error.response.data.ErrorMessage);
            })
    }, []);

    return (<div>
        <CalendarHeader isLoading={false} />
        <div style={{maxWidth: "890px", margin: "0 auto", textAlign: "justify"}} 
        dangerouslySetInnerHTML={{__html: pageContent}}></div>
        {error && <div style={{maxWidth: "890px", margin: "0 auto", textAlign: "justify"}}>
            <div className={"p-5 my-2 bg-red-100 rounded"}>
                <div className="flex">
                    <div className="text-red-500">
                        <svg className="pr-1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="flex-1 text-red-800 font-bold">
                       {t(error)}
                    </div>
                </div>
                
            </div>
        </div>}
    </div>)
}