import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorDetails, ErrorResponse } from '../../../utility/ErrorDetails';
import styles from './index.module.scss';

export const ErrorAlert = ({serverError, onDismiss}: {serverError:ErrorResponse, onDismiss:any}) => {
    const { t } = useTranslation();
    return (<div className={"p-2 my-2 bg-red-100 rounded"}>
    <div className="flex">
        <div className="text-red-500"> 
            <svg className="pr-1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
        </div>
        <div className="flex-1 text-red-800 font-bold"> 
        {Object.keys(serverError?.errors).length !== 1 
            ? t("MultipleErrorsWithSumbmission", {errorCount: Object.keys(serverError?.errors).length})
            : t("SingleErrorWithSumbmission")}
        </div>
        <div>
            <button onClick={onDismiss}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    </div>
    <div>
        <ul className={styles.errorList + " mt-3 list-disc text-red-700"}>
            {Object.entries(serverError?.errors).map(([key,value], index:number) => 
                <li key={index}>
                    {t(key)}: { value.map(x => t(x)).join(", ")}
                </li>
            ) || t("UnknownError")} 
        </ul>
    </div>
</div>);
}