import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderModel } from '../../../../store/slices/OrderSlice/OrderModel';


export const OrderStatus = ({order}:{order:OrderModel}) => {
    const { t } = useTranslation();
    return (
    <div>
        {t("OrderStatusLabel")}: &nbsp;
        {order.IsPaid 
        ? t("Paid") + "✔️" 
        : (order.IsExpired ? t("Order Expired") + "❌" :  t("Waiting for payment") + "💸")}
    </div>)
}