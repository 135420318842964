import React from 'react';
import { Room } from '../../../../../store/slices/ScheduleSlice/ScheduleModel';
import styles from './index.module.scss';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { PriceRuleModel } from '../../../../../store/slices/CompanySlice/PriceRuleModel';
import { PriceRuleType } from '../../../../../store/enums/PriceRuleType';
import moment from 'moment';
import { WeekDayFlag } from '../../../../../store/enums/WeekDayFlag';
import { PriceRuleSerice } from '../../../../../services/PriceRuleService';

export const Preview = ({ rooms }: { rooms: Room[] }) => {
    const priceRules = useSelector((state:RootState) => state.company?.PriceRules)
    ?.filter(rule => rule.Type == PriceRuleType.TimeOfADayPriceRule) as PriceRuleModel[];
    const selectedSlotsCount = rooms.filter(x => Object.keys(x.SelectedSlots).length > 0).reduce((sum, current) => sum + Object.keys(current.SelectedSlots).length, 0);
    const isRuleAplicable = (rule: PriceRuleModel, slot: any): boolean => {
        return slot.hour >= (rule.StartHour ?? -1)
            && slot.hour < (rule.EndHour ?? 30)
            && (rule.WeekDaysRestriction == WeekDayFlag.None 
                || ((rule.WeekDaysRestriction & slot.dayOfWeek) == slot.dayOfWeek));
    }
    
    const total = rooms
        .filter(x => Object.keys(x.SelectedSlots).length > 0)
        .reduce((sum, room) => {

            const roomPriceRules = priceRules.filter(x => x.RoomId == room.RoomId);
            if( roomPriceRules.length) {
                return sum 
                + Object.keys(room.SelectedSlots).reduce((prev, curr) => {
                    const selectedDate = moment(curr, "DDMMYYHH"); //25112111
                    const dow =  PriceRuleSerice.isoDayOfWeekToWeekDayFlag(selectedDate.isoWeekday());
                    const hour = selectedDate.hour();
                    
                    return prev + room.PricePerSlot + roomPriceRules.reduce((prev2, rule) => 
                    isRuleAplicable(rule, {hour, dayOfWeek: dow}) 
                    ? prev2 + rule.PriceDifference
                    : prev2
                    , 0)
                }, 0);
            } else {
                return sum + Object.keys(room.SelectedSlots).length * room.PricePerSlot;
            }

        }, 0);
    
    return (
        <div className={`${styles.checkoutPreview} bg-white transition-all flex justify-between
        px-2 py-4 ${selectedSlotsCount ? "" : "hidden"}
        `}>
            {<>
                <div className="self-center">
                    {selectedSlotsCount} години
                     на суму {total} UAH
                     </div>
                <div>
                    <Link to="/checkout" >
                        <button
                            className="p-2 border bg-blue-600 text-white font-bold hover:bg-blue-700">Перейти до оформлення</button>
                    </Link>
                </div>
            </>}
        </div>
    )
}