import React from 'react';
import { useTranslation } from 'react-i18next';

export const AvailableLoyaltyPoints = (
    { loyaltyPointsAvilableForThisOrder, 
        useLoyaltyPoints, 
        setUseLoyaltyPoints 
    } : { 
        loyaltyPointsAvilableForThisOrder: number, 
        useLoyaltyPoints: boolean, 
        setUseLoyaltyPoints: any 
    }) => {
    const { t } = useTranslation();
    return (<div className='font-semibold'>
        <label htmlFor="useLoyaltyPoints">{t("UseLoyaltyPointsToPayOrder", { loyaltyPointsAvilableForThisOrder: loyaltyPointsAvilableForThisOrder })}</label>
        <input id='useLoyaltyPoints' type="checkbox" checked={useLoyaltyPoints}
            style={{ marginLeft: 5 }}
            onChange={(e) => setUseLoyaltyPoints(e.target.checked)} />
    </div>);
}