import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CheckoutModel } from "./CheckoutModel";

const initialCheckout:CheckoutModel = {
OrderComment: ""
};

export const CheckoutSlice = createSlice({
    name: "checkout",
    initialState: initialCheckout,
    reducers: {
        setComment: (state: CheckoutModel, action:PayloadAction<string>) => {
            state.OrderComment = action.payload;
            return state;
        }
    }
})