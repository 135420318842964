import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from 'moment';
import 'moment/locale/uk';

export const InitLocalization = () => {
    i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: {
          translation: {
            "Mon": "Mon.",
            "Tue": "Tue.",
            "Wed": "Wed.",
            "Thu": "Thu.",
            "Fri": "Fri.",
            "Sat": "Sat.",
            "Sun": "Sun.",
            "PickRoom": "Pick a room",
            "photostudio": "Photo studio",
            "notacceptingbooking": "No available for booking",
            "open": "Available for booking",
            "busy" : "No available for booking",
            "selected": "Selected",
            "Booking Page Header": "Order Details",
            "room" : "Room",
            "BookingDetails": "Booking Details",
            "Comment": "Comment",
            "Phonenumber": "Phonenumber",
            "Email": "Email",
            "ContactName": "Name",
            "Contact Information" : "Contact Information",
            "Book" : "Book",
            "Edit" : "Edit",
            "UAH": "UAH",
            "ReqiredField": "required field",
            "InvalidField": "invalid field",
            "OrderDetails": "Order Details",
            "OrderStatusLabel": "Order status",
            "Rooms Details": "Room",
            "User details": "User",
            "Payment details": "Payment",
            "Paid": "Paid",
            "Waiting for payment" : "Waiting for payment",
            "Order Expired": "Order Expired",
            "SingleErrorWithSumbmission": "There is 1 error with your submission.",
            "MultipleErrorsWithSumbmission": "There are a few erros with your sumission.",
            "TimeSlotInPast": "One or multiple of your selected time is in the past regarding company local time.",
            "TimeSlotOutsideWorkingHours": "One or multiple of your selected time is outside of company working hours.",
            "ConcurencyConflict": "Oops. Looks like someone just booked one or multple of your selected time and this time is no longer available, try select another hour(s).",
            "InvalidUserProp": "Something went wrong with validation contact information.",
            "NoTimeslotSelected": "Please select time",
            "NumberOfPersons": "Number of persons",
            "UnknownError": "Unknown error",
            "SocialNetworkLink": "Social Network",
            "BeWarned": "Be Warned",
            "PayTimeWarning": "Order has to be payed within {{minutesToPay}} minutes after submission or it will be canceled automaticly.",
            "OrderTotal": "Order Total",
            "InfoOrderAutoCancelIn": "Order will be automaticaly canceled in {{ExpireInMinutes}} minutes if we didn't receive a payment.",
            "Hour": "hour",
            "PublicOfferAgreement": "Public offer agreement",
            "PaymentAndCancelation":"Payment/Cancelation",
            "CompanyAbout": "About Us",
            "RouteDetails": "Route Details",
            "Schema": "Schema",
            "WeOnMap": "Us on map",
            "IAgreeTo":  "I agree to",
            "StudioRules": "studio rules",
            "ErrorRetrivingPageContent": "There was an error loading this page",
            "DomainValidations" : "Error",
            "ApplicationErrors" : "Error",
            "PayWithMonobank": "Pay with",
            "SignOut": "Sign Out",
            "RedeemLoyaltyPoints": "Redeem loyalty points",
            "UseLoyaltyPointsToPayOrder": "Use loyalty points ({loyaltyPointsAvilableForThisOrder})",
            "LoginToReceiveLoyaltyPoints": "Login to receive {{loyaltyPoints}} UAH bonuses",
            "PointsAccured": "Accured {{amount}} bonuses",
            "LoyaltyProgramLoginNote": "* use to participate in loyalty program"
          }
        },
        uk: {
          translation: {
            "Mon": "Пн.",
            "Tue": "Вт.",
            "Wed": "Ср.",
            "Thu": "Чт.",
            "Fri": "Пт.",
            "Sat": "Сб.",
            "Sun": "Нд.",
            "PickRoom": "Оберіть зал",
            "photostudio": "Студія фотографії",
            "notacceptingbooking": "Не доступний для бронювання",
            "open": "Доступний для бронювання",
            "busy" : "Не доступний для бронювання",
            "selected": "Вибраний",
            "Booking Page Header": "Оформлення замовлення",
            "room" : "Зал",
            "BookingDetails": "Деталі бронювання",
            "Comment": "Коментар",
            "Phonenumber": "Телефон",
            "Email": "Емейл",
            "ContactName": "Ім'я",
            "Contact Information": "Контактна інформація",
            "Book": "Забронювати",
            "Edit": "Редагувати",
            "UAH": "грн",
            "ReqiredField": "обовязкове до заповнення",
            "InvalidField": "недійсний",
            "OrderDetails": "Деталі замовлення",
            "OrderStatusLabel": "Статус замовлення",
            "Rooms Details": "Зал",
            "User details": "Користувач",
            "Payment details": "Оплата",
            "Paid": "Оплачено",
            "Waiting for payment": "Очікується оплата",
            "Order Expired": "Замовлення скасовано",
            "SingleErrorWithSumbmission": "Сталась помилка з вашим бронюванням.",
            "MultipleErrorsWithSumbmission": "Сталось декілька помилок з вашим бронюванням.",
            "TimeSlotInPast": "Один або декілька з вибраних годин знаходяться в минулому відносно локального часу компанії.",
            "TimeSlotOutsideWorkingHours": "Один або декілька з вибраних годин позаробочим часом компанії.",
            "ConcurencyConflict": "Уупс. Виглядає так, що хтось щойно забронював один або декілька з вибраних вами годин і ці години більше не доступні для бронювання, спробуйте обрати інші.",
            "InvalidUserProp": "Сталась помилка з валідацією контактної інформації",
            "NoTimeslotSelected": "Будь ласка виберіть час який хочете забронювати",
            "NumberOfPersons": "Скільки буде людей",
            "UnknownError": "Невідома помилка",
            "SocialNetworkLink": "Лінк на соц. мережу",
            "BeWarned": "Увага",
            "PayTimeWarning": "У разі не внесення оплати після бронювання замовлення скасовується автоматично через {{minutesToPay}} хвилин.",
            "OrderTotal" : "До сплати",
            "InfoOrderAutoCancelIn": "В разі не оплати замовлення буде автоматично скасовано через {{ExpireInMinutes}} хвилин.",
            "Hour": "година",
            "PublicOfferAgreement": "Договір публічної оферти",
            "PaymentAndCancelation": "Оплата/Скасування",
            "CompanyAbout": "Про компанію",
            "RouteDetails": "Деталі проїзду",
            "Schema": "Схема",
            "WeOnMap": "Ми на карті",
            "IAgreeTo":  "Я погоджуюсь з",
            "StudioRules": "правилами студії",
            "AgreeToRules": "Підтвердіть що погоджується з правилами студії",
            "ErrorRetrivingPageContent": "Сталась помилка завантаження сторінки",
            "DomainValidations" : "Помилка",
            "ApplicationErrors" : "Помилка",
            "PayWithMonobank": "Оплатити онлайн з",
            "SignOut": "Вийти",
            "RedeemLoyaltyPoints": "Використання бонусів",
            "UseLoyaltyPointsToPayOrder": "Використати бонуси ({{loyaltyPointsAvilableForThisOrder}})",
            "LoginToReceiveLoyaltyPoints": "Увійдіть в акаунт щоб отримати {{loyaltyPoints}} грн. бонусів",
            "PointsAccured": "Нараховано {{amount}} бонусів",
            "LoyaltyProgramLoginNote": "* діє програма лояльності"
          }
        }
    },
    lng: "uk",
    fallbackLng: "uk",
  
    interpolation: {
        escapeValue: false
    }
}, () => {
  moment.locale(i18n.language);
});
}
