import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Footer = () => {

    const [companyAboutLink, setCompanyAboutLink] = useState("#");
    const [showPoweredBy, setShowPoweredBy] = useState(false);

    useEffect(() => {
        if(window.location.hostname === "booking.lightqb.com") {
            setCompanyAboutLink("https://lightqb.com/");
        } else if (window.location.hostname === "cinemaart.planeronline.com") {
            setCompanyAboutLink("https://cinemaart.com.ua");
        }

        if(window.location.hostname !== "cinemaart.planeronline.com") {
            setShowPoweredBy(true);
        }
    }, [])

    const {t} = useTranslation();
    return (<div className="bg-gray-100 flex mt-5 py-2">
        <div className="m-auto" style={{ maxWidth: "890px" }}>
            <div className="flex justify-around">
                <Link to="/public-offer-agreement" className="hover:underline text-blue-700">{t("PublicOfferAgreement")}</Link>
            </div>
            <div className="flex justify-around"> 
                <a target="_blank" rel="noreferrer" href="/rules" 
                className="hover:underline text-blue-700 ">{t("PaymentAndCancelation")}</a>
            </div>
            <div className="flex justify-around"> 
                <a target="_blank" rel="noreferrer" href={companyAboutLink} 
                className="hover:underline text-blue-700 ">{t("CompanyAbout")}</a>
            </div>
            <div className="flex justify-around">
                <img style={{width: "150px", height: "50px"}} alt="master card or visa" src="https://planeronlineprd.blob.core.windows.net/public/liqpay-mc-v.png" />
            </div>
            {showPoweredBy && <div className="flex justify-around text-gray-600">
                <span className="whitespace-pre"> Powered by </span> <a target="_blank" href="https://www.planeronline.com"> PlanerOnline.com</a>
            </div>}

        </div>
    </div>);
}