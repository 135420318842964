import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserAccountModel } from "./UserAccountModel";

const defaultUserAccount: UserAccountModel = {
    Authenticated: false,
    IsLoaded: false,
    CurrentBalance: 0,
    Name: "",
    Phonenumber: "",
    Email: "",
    PhotoUrl: ""
};

export const UserAccountSlice = createSlice({
    name: "userAccount",
    initialState: defaultUserAccount,
    reducers: {
        setUserAccount: (state: UserAccountModel, action:PayloadAction<UserAccountModel>) => {
            state.Authenticated = action.payload.Authenticated;
            state.IsLoaded = true;
            state.CurrentBalance = action.payload.CurrentBalance;
            state.Name = action.payload.Name;
            state.Phonenumber = action.payload.Phonenumber;
            state.Email = action.payload.Email;
            state.PhotoUrl = action.payload.PhotoUrl;
        },
        setEmail: (state: UserAccountModel, action:PayloadAction<string>) => {
            state.Email = action.payload;
        },
        setName: (state: UserAccountModel, action:PayloadAction<string>) => {
            state.Name = action.payload;
        },
        setPhonenumber: (state: UserAccountModel, action:PayloadAction<string>) => {
            state.Phonenumber = action.payload;
        },
    }
});