import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserModel } from "./UserModel";

const initialUser:UserModel = {
Email: "",
Name: "",
Phonenumber: "",
SocialNetworkLink: "",
};

export const UserSlice = createSlice({
    name: "user",
    initialState: initialUser,
    reducers: {
        setUser: (state: UserModel, action:PayloadAction<UserModel>) => {
            state.Name = action.payload.Name ?? '';
            state.Phonenumber = action.payload.Phonenumber ?? '';
            state.Email = action.payload.Email ?? '';
        },
        setEmail: (state: UserModel, action:PayloadAction<string>) => {
            state.Email = action.payload;
        },
        setName: (state: UserModel, action:PayloadAction<string>) => {
            state.Name = action.payload;
        },
        setPhonenumber: (state: UserModel, action:PayloadAction<string>) => {
            state.Phonenumber = action.payload;
        },
        setSocialNetworkLink: (state: UserModel, action:PayloadAction<string>) => {
            state.SocialNetworkLink = action.payload;
        },
    }
})