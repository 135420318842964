import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const LoginForPoints = ({ companyLoyalties, total }: { companyLoyalties: any, total: number }) => {
    const { t } = useTranslation();

    const avilableLoyaltyPoints = useMemo(() =>  {
        let availableDiscount = 0;
        companyLoyalties.activeLoyalties?.forEach((element: any) => {
            availableDiscount += element.DiscountPercent;
        });
        return availableDiscount;
    }, [companyLoyalties]);

    return (<div className='font-semibold'>
        {t("LoginToReceiveLoyaltyPoints", { loyaltyPoints: ((avilableLoyaltyPoints * total) / 100).toFixed(2) })}
        <span className="cursor-pointer ml-1 has-tooltip">
            <span className='tooltip rounded shadow-lg p-2 bg-black text-gray-100 whitespace-pre-line text-base' style={{ maxWidth: 200, transform: "translateY(28px)" }}>
                {companyLoyalties.activeLoyalties?.map((loyalty: any, index: any) => {
                    return (
                        <div key={index}>
                            <div>{loyalty.Name} ({loyalty.DiscountPercent}%)</div>
                        </div>
                    );
                })}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" style={{ display: 'inline' }} fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        </span>
    </div>)
}