import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { CompanyInfoModel } from '../../../../store/slices/CompanySlice/CompanyInfoModel';
import { ServerConfig } from '../../../../utility/ServerConfig';
import { CompanySlice } from '../../../../store/slices/CompanySlice';
import Skeleton from 'react-loading-skeleton';
import { Link, useRouteMatch } from 'react-router-dom';
import { TelegramUser } from '../telegramLoginButton';
import TelegramLoginButton from '../telegramLoginButton';
import { UserAccountSlice } from '../../../../store/slices/UserAccountSlice';
import { UserSlice } from '../../../../store/slices/UserSlice';
import { UserAccountModel } from '../../../../store/slices/UserAccountSlice/UserAccountModel';
import { UserModel } from '../../../../store/slices/UserSlice/UserModel';
import { useOutsideAlerter } from '../../../../hooks/useOutsideAlerter';

export const CalendarHeader = ({ isLoading }: { isLoading: Boolean }) => {
    const company = useSelector((state: RootState) => state.company);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userAccount = useSelector((state: RootState) => state.userAccount);
    const order = useSelector((state: RootState) => state.order);
    const [accountExpanded, setAccountExpanded] = useState(false);
    let { orderId } = useRouteMatch<{ orderId: string }>().params;
    const menuRef = useRef(null);
    useOutsideAlerter(menuRef, () => setAccountExpanded(false));

    const loadUser = () => {
        return axios.get(`${ServerConfig.baseUrl}/api/userAccounts/me`)
            .then((response: any) => {
                const { setUserAccount } = UserAccountSlice.actions;
                const { setUser } = UserSlice.actions;

                dispatch(setUserAccount(response.data as UserAccountModel));
                dispatch(setUser(response.data as UserModel));
            });
    }

    const handleTelegramResponse = useCallback(
        (user: TelegramUser) => {

            let dataCheckingString = Object.entries(user)
            .filter(x => x[0] !== "hash")
            .sort((a,b) => a[0].localeCompare(b[0]))
            .reduce((p, c) => `${p}\n${c[0]}=${c[1]}`, '')
            .trim();

            axios.post(`${ServerConfig.baseUrl}/api/ThirdPartyLogin`, {
                ProviderUserIdentifier: user.id.toString(),
                FirsName: user.first_name,
                Hash: user.hash,
                LastName: user.last_name,
                PhotoUrl: user.photo_url,
                UserName: user.username,
                AuthDate: user.auth_date,
                DataCheckingString: dataCheckingString,
                OrderGuid: orderId
            }).then(response => {
                loadUser();
            });
        }, []);

    const onLogout = () => {
        axios.post(`${ServerConfig.baseUrl}/api/ThirdPartyLogin/logout`)
            .then(response => {
                loadUser();
            });
    }

    useEffect(() => {
        if (!isLoading && !company) {
            axios.get<CompanyInfoModel>(`${ServerConfig.baseUrl}/api/rooms`)
                .then(response => {
                    dispatch(CompanySlice.actions.setCompany(response.data));
                })
        }
    }, []);

    return (<div>
        <div className="flex bg-gray-100 px-2">
            <Link to="/">
                {(company && <div className={`${styles.companyLogo} self-center mr-3`} style={{ "backgroundImage": `url(${company.LogoUrl})` }}></div>)
                    || <Skeleton circle={true} height={50} width={50} />}
            </Link>
            <div className="inline-block m-1 " >
                <div className="text-lg border-b-2 border-slate-200">
                    {company?.CompanyName || <Skeleton count={1} width="50px" />}
                </div>
                <div className="text-gray-600 text-xs">
                    {t(company?.CompanyType || "") || <Skeleton count={1} width="50px" />}
                </div>
            </div>

            <div style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto" }}>
                {!userAccount.Authenticated
                    && userAccount.IsLoaded
                    && !!company?.LoginTelegramBotName
                    && company?.HasActiveLoyalty &&
                    <><div style={{marginTop: 5}}><TelegramLoginButton
                        dataOnAuth={handleTelegramResponse}
                        botName={company.LoginTelegramBotName} />
                        </div>
                        <div style={{fontSize: 12}}>{t("LoyaltyProgramLoginNote")}</div>
                    </>
                }

                {userAccount.Authenticated && userAccount.IsLoaded &&
                    <div className="relative inline-block text-left">
                        <div>
                            <button type="button"
                                className="inline-flex w-full justify-center gap-x-1.5 rounded-md border rounded px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                aria-expanded={accountExpanded}
                                aria-haspopup="true"
                                onClick={() => setAccountExpanded(!accountExpanded)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" className="w-6 h-6 mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                </svg>

                                {userAccount.Name}
                            </button>
                        </div>
                        {accountExpanded && <div 
                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" 
                        role="menu" aria-orientation="vertical" 
                        aria-labelledby="menu-button" 
                        tabIndex={-1}
                        ref={menuRef}>
                            <div className="py-1" role="none">
                                <button type="button"
                                    className="text-gray-700 block w-full px-4 py-2 text-left text-sm"
                                    role="menuitem" tabIndex={-1}
                                    onClick={() => {
                                        setAccountExpanded(false);
                                        onLogout();
                                    }}
                                >{t("SignOut")}</button>
                            </div>
                        </div>}
                    </div>
                }
            </div>

        </div>
    </div>);
}