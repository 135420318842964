export type WeekSchedule =  {
        WeekStartDate:string;
        MinHour:number;
        MaxHour:number;
        Monday:DaySchedule;
        Tuesday:DaySchedule;
        Wednesday:DaySchedule;
        Thursday:DaySchedule;
        Friday:DaySchedule;
        Saturday:DaySchedule;
        Sunday:DaySchedule;
        Timeslots: DayTimeslot[];
        WorkingHourOverrides: DateSchedule[];
}

export type DayTimeslot =  {
    y: Number;
    m: Number;
    d: Number;
    h: Number;
    status: SlotStatus;
}

export enum SlotStatus {
    NotAcceptingBooking = "NotAcceptingBooking",
    Open = "Open",
    Busy = "Busy",
    Selected = "Selected"
}


export type DaySchedule =  {
    Start:number;
    End:number;
}

type KeyValuePair<TValue>  = {
    [key: string]: TValue
}

export type Room = {
    Schedule:WeekSchedule;
    RoomId: number;
    RoomSlug:string;
    RoomName:string;
    SelectedSlots:KeyValuePair<boolean>;
    PricePerSlot: number;
}

export type ScheduleRooms = {
    [x: string]: Room;
}

export type Schedule = {
    SelectedWeek:string;
    Rooms:ScheduleRooms;
}

export type DateSchedule = DaySchedule & {
    Date: Date;
}