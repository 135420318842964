import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CheckoutSlice } from '../../../../store/slices/CheckoutSlice';
import { UserSlice } from '../../../../store/slices/UserSlice';
import { RootState } from '../../../../store/store';
import InputMask from 'react-input-mask';

export const UserDetailsForm = () => {
    const { t } = useTranslation();
    const { Name, Email, Phonenumber, SocialNetworkLink } = useSelector((state: RootState) => state.user);
    const orderComment = useSelector((state: RootState) => state.checkout.OrderComment);
    const dispatch = useDispatch();

    return (
        <div>
            <h1 className="text-2xl">{t("Contact Information")}</h1>
            <div className="my-3">
                <div className={"outline-float relative border-2 focus-within:border-black border-gray-400"}>
                    <input type="text" name="name"
                        placeholder=" "
                        value={Name}
                        autoComplete="given-name"
                        maxLength={50}
                        onChange={(e) => dispatch(UserSlice.actions.setName(e.target.value))}
                        className="block p-2 w-full text-lg appearance-none focus:outline-none bg-transparent" />
                    <label htmlFor="name" className="absolute top-0 text-lg bg-white p-2 z-n1 duration-300 origin-0 text-gray-400">
                        {t("ContactName")}
                    </label>
                </div>
            </div>
            <div className="my-3">
                <div className={"outline-float relative border-2 focus-within:border-black border-gray-400"}>
                    <input type="text" name="email"
                        placeholder=" "
                        maxLength={320}
                        value={Email}
                        autoComplete="email"
                        onChange={(e) => dispatch(UserSlice.actions.setEmail(e.target.value))}
                        className="block p-2 w-full text-lg appearance-none focus:outline-none bg-transparent" />
                    <label htmlFor="email" className="absolute top-0 text-lg bg-white p-2 z-n1 duration-300 origin-0 text-gray-400">
                        {t("Email")}
                    </label>
                </div>
            </div>

            <div className="my-3">
                <div className={"outline-float relative border-2 focus-within:border-black border-gray-400"}>
                    <InputMask  name="phonenumber"
                        placeholder=" "
                        mask="+38 099 999 99 99"
                        value={Phonenumber}
                        autoComplete="tel"
                        onChange={(e) => dispatch(UserSlice.actions.setPhonenumber(e.target.value))}
                        className="block p-2 w-full text-lg appearance-none focus:outline-none bg-transparent" />
                    <label htmlFor="phonenumber" className="absolute top-0 text-lg bg-white p-2 z-n1 duration-300 origin-0 text-gray-400">
                        {t("Phonenumber")}
                    </label>
                </div>
            </div>

            <div className="my-3">
                <div className={"outline-float relative border-2 focus-within:border-black border-gray-400"}>
                    <input type="text" name="socialnetworklink"
                        placeholder=" "
                        value={SocialNetworkLink}
                        onChange={(e) => dispatch(UserSlice.actions.setSocialNetworkLink(e.target.value))}
                        className="block p-2 w-full text-lg appearance-none focus:outline-none bg-transparent" />
                    <label htmlFor="socialnetworklink" className="absolute top-0 text-lg bg-white p-2 z-n1 duration-300 origin-0 text-gray-400">
                        {t("SocialNetworkLink")}
                    </label>
                </div>
            </div>

            <div className="my-3">
                <div className="outline-float relative border-2 focus-within:border-black border-gray-400">
                    <textarea name="comment"
                        className="block p-2 w-full text-lg appearance-none focus:outline-none bg-transparent"
                        placeholder=" "
                        maxLength={1000}
                        value={orderComment}
                        onChange={(e) => dispatch(CheckoutSlice.actions.setComment(e.target.value))}></textarea>
                    <label htmlFor="comment"
                        className="absolute top-0 text-lg bg-white p-2 z-n1 duration-300 origin-0 text-gray-400">
                        {t("Comment")}
                    </label>
                </div>
            </div>
            <div>

            </div>
        </div>
    );
}