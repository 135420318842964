import React, { useEffect } from 'react';
import styles from './scheduler.module.scss';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { DayColumn } from '../DayColumn';
import { TimeReference } from '../TimeReference';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduleSlice } from '../../../../../store/slices/ScheduleSlice'
import { Room } from '../../../../../store/slices/ScheduleSlice/ScheduleModel';
import { RootState } from '../../../../../store/store';
import moment from 'moment';
import { ServerConfig } from '../../../../../utility/ServerConfig';
import Skeleton from 'react-loading-skeleton';

const Scheduler = () => {
    let { roomSlug } = useRouteMatch<{roomSlug: string}>().params;
    const dispatch = useDispatch();
    const selectedWeekStartDate = useSelector((state:RootState) => state.schedule.SelectedWeek);
    const roomSchedule = useSelector((state:RootState) => state.schedule.Rooms[roomSlug]);
    const company = useSelector((state:RootState) => state.company);

    useEffect(() => {
        axios.get<Room>(`${ServerConfig.baseUrl}/api/rooms/${roomSlug}/slots/${selectedWeekStartDate}`)
            .then(response => {
                dispatch(ScheduleSlice.actions.addRoomSchedule(response.data));
            });
    }, [selectedWeekStartDate, roomSlug, dispatch]);

    
    const { t } = useTranslation();
    if(!roomSchedule) return (<div>
        <Skeleton count={1} height="20px" />
        <Skeleton count={9} height="30px" />
    </div>);
    var maxHour = roomSchedule.Schedule.MaxHour;
    var minHour = roomSchedule.Schedule.MinHour;
    const timeReference:Number[] = [];
    for(;minHour < maxHour; minHour++) {
        timeReference.push(minHour);
    }
    
    timeReference.push(maxHour);
    const timeReferenceDom = TimeReference(timeReference);
    const weekStart = moment(roomSchedule.Schedule.WeekStartDate, "DD-MM-YYYY").utcOffset(company?.Timezone || 0, true);

    const getDaySchedule = (weekDay:any, daySchedule:any) => {
        return roomSchedule.Schedule.WorkingHourOverrides.find(who => weekDay.isSame(who.Date))
        ?? daySchedule;
    }

    return (
        <div className="overflow-x-auto">
            <div className={`${styles.schedulerWraper}`}>
                <div className="text-right whitespace-nowrap sticky left-0" style={{backgroundColor: "rgba(255,255,255,.7)"}}>
                    {timeReferenceDom}
                </div>
                <div>
                    <div className={"mx-1 mb-4 text-center"}>{t("Mon")} {weekStart.format("DD.MM")}</div>
                    <DayColumn day={ getDaySchedule(weekStart, roomSchedule.Schedule.Monday)} 
                    min={roomSchedule.Schedule.MinHour}
                    max={roomSchedule.Schedule.MaxHour}
                    date={weekStart.clone()} />
                </div>
                <div>
                    <div className={"mx-1 mb-4 text-center"}>{t("Tue")} {weekStart.clone().add(1, "day").format("DD.MM")}</div>
                    <DayColumn day={ getDaySchedule(weekStart.clone().add(1, "day"), roomSchedule.Schedule.Tuesday) } 
                    min={roomSchedule.Schedule.MinHour}
                    max={roomSchedule.Schedule.MaxHour}
                    date={weekStart.clone().add(1, "d")} />
                </div>
                <div>
                    <div className={"mx-1 mb-4 text-center"}>{t("Wed")} {weekStart.clone().add(2, "day").format("DD.MM")}</div>
                    <DayColumn day={ getDaySchedule(weekStart.clone().add(2, "day"), roomSchedule.Schedule.Wednesday) }
                    min={roomSchedule.Schedule.MinHour}
                    max={roomSchedule.Schedule.MaxHour}
                    date={weekStart.clone().add(2, "d")} />
                </div>
                <div>
                    <div className={"mx-1 mb-4 text-center"}>{t("Thu")} {weekStart.clone().add(3, "day").format("DD.MM")}</div>
                    <DayColumn day={ getDaySchedule(weekStart.clone().add(3, "day"), roomSchedule.Schedule.Thursday) }
                    min={roomSchedule.Schedule.MinHour}
                    max={roomSchedule.Schedule.MaxHour}
                    date={weekStart.clone().add(3, "d")} />
                </div>
                <div>
                    <div className={"mx-1 mb-4 text-center"}>{t("Fri")} {weekStart.clone().add(4, "day").format("DD.MM")}</div>
                    <DayColumn day={ getDaySchedule(weekStart.clone().add(4, "day"), roomSchedule.Schedule.Friday) }
                    min={roomSchedule.Schedule.MinHour}
                    max={roomSchedule.Schedule.MaxHour}
                    date={weekStart.clone().add(4, "d")} />
                </div>
                <div>
                    <div className={"mx-1 mb-4 text-center"}>{t("Sat")} {weekStart.clone().add(5, "day").format("DD.MM")}</div>
                    <DayColumn day={ getDaySchedule(weekStart.clone().add(5, "day"), roomSchedule.Schedule.Saturday) }
                    min={roomSchedule.Schedule.MinHour}
                    max={roomSchedule.Schedule.MaxHour}
                    date={weekStart.clone().add(5, "d")} />
                </div>
                <div>
                    <div className={"mx-1 mb-4 text-center"}>{t("Sun")} {weekStart.clone().add(6, "day").format("DD.MM")}</div>
                    <DayColumn day={ getDaySchedule(weekStart.clone().add(6, "day"), roomSchedule.Schedule.Sunday) }
                    min={roomSchedule.Schedule.MinHour}
                    max={roomSchedule.Schedule.MaxHour}
                    date={weekStart.clone().add(6, "d")} />
                </div>
                <div className="whitespace-nowrap">
                    {timeReferenceDom}
                </div>
            </div>
        </div>
    )
}

export default Scheduler;