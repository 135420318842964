import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Room } from '../../../../store/slices/ScheduleSlice/ScheduleModel';
import { PriceAdjustments } from './priceAdjustments';

export const RoomDetails = ({room, appliedPriceRules} : {room:Room, appliedPriceRules:any[] }) => {
    const { t } = useTranslation();
    const selectedSlots = Object.keys(room.SelectedSlots).map(c => moment(c, "DDMMYYhh")).sort();
    const mergedRanges:any[] = [];
    selectedSlots.forEach(x => {
        if(mergedRanges.length === 0) {
            mergedRanges.push({start: x, end: x.clone().add(1, "h")})
        } else if (mergedRanges[mergedRanges.length - 1].end.isSame(x)) {
            mergedRanges[mergedRanges.length -1].end.add(1, "h");
        } else {
            mergedRanges.push({start: x, end: x.clone().add(1, "h")});
        }
    });
    return (<div key={room.RoomSlug}>
        <div className="border border-t-0 border-l-0 border-r-0 text-lg text-gray-600"> {t("room")} {room.RoomName} </div>
        <div> 
            {mergedRanges.map(c => 
            <div key={c.start.format()} className="border border-t-0 border-l-0 border-r-0 flex">
                <div className="flex-1">
                    {c.start.format("DD MMMM HH:mm")} - {c.end.format("HH:mm")}
                </div>
                <div className=""> 
                    {c.end.diff(c.start, "hours") * room.PricePerSlot} {t("UAH")}
                </div>
            </div> 
            )} 
        </div>
        <div>
            <PriceAdjustments appliedPriceRules={appliedPriceRules[room.RoomId] ?? []} />
        </div>
    </div>)
}