import React, { useEffect, useState } from 'react';
import { Switch, Route} from 'react-router-dom';
import { RoomCalendar } from './components/calendar/RoomCalendar';
import { CalendarHeader } from './components/calendarHeader';
import { RoomsList } from './components/roomsList';
import { useDispatch } from 'react-redux';
import { CompanySlice } from '../../store/slices/CompanySlice'
import axios from 'axios';
import { CompanyInfoModel } from '../../store/slices/CompanySlice/CompanyInfoModel';
import { ServerConfig } from '../../utility/ServerConfig';
import { BookingDetails } from './components/bookingDetails';

const BookingPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const dispatch = useDispatch();
    useEffect(() => {
        setIsLoading(true);
        axios.get<CompanyInfoModel>(`${ServerConfig.baseUrl}/api/rooms`)
            .then(response => {
                setIsLoading(false);
                dispatch(CompanySlice.actions.setCompany(response.data));
            })
    }, [dispatch]);


    return (
        <div>
            <CalendarHeader isLoading={isLoading} />
            
            <Switch>
                <Route path="/checkout">
                    <BookingDetails />
                </Route>
                <Route path="/rooms/:roomSlug">
                    <RoomCalendar />
                </Route>
                <Route path="/rooms">
                    <RoomsList /> 
                </Route>
            </Switch>
        </div>)
};

export default BookingPage;