import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { RootState } from '../../../../../store/store';


export const AvailableRooms = () => {

    const company = useSelector((state: RootState) => state.company);
    let { roomSlug } = useRouteMatch<{roomSlug: string }>().params;

    return (<div className="my-4 text-2xl text-right">
        {company?.Rooms.map(room =>
            (<span className={`mx-2 inline-block`} key={room.Slug}>
                <Link to={`/rooms/${room.Slug}`} key={room.Slug} 
                className={`text-blue-600 hover:underline p-1 ${ room.Slug === roomSlug ? "bg-gray-200" : ""}`}>
                    {room.Heading}
                </Link>
            </span>)
        ) || <Skeleton count={1} width="200px" />}
    </div>)
}