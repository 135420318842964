import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import Skeleton from "react-loading-skeleton";

export const RoomsList = () => {
    const company = useSelector((state: RootState) => state.company);
    const { t } = useTranslation();

    return (<div className="mx-auto mt-10 app-container">

        {<div className="lg:p-0 md:p-3">
            <h1 className="text-2xl">{t("PickRoom")}</h1>
            <div className="">
                {company?.Rooms.map(room =>
                    (<div className={`md:w-1/3 sm:w-full inline-block md:px-1`} key={room.Slug}>
                        <Link to={`/rooms/${room.Slug}`} key={room.Slug} className="inline-block relative">
                            <img src={room.PictureUrl} alt={room.Heading} />
                            <div className={`${styles.roomLinkInfo} absolute bottom-0 left-0 right-0 text-xl pl-1`}>
                                {room.Heading} {room.Subheading}
                            </div>
                        </Link>
                    </div>)
                ) || <Skeleton count={1} width="33.333%" height="150px" />}
            </div>
        </div>}

    </div>);
}