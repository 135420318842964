import moment from 'moment';
import React from 'react';


export const RoomDetails = ({roomName, room}: {roomName:string, room:string[]}) => {
    const selectedSlots = room.map(c => moment(c, "DDMMYYhh")).sort();
    const mergedRanges:any[] = [];
    selectedSlots.forEach(x => {
        if(mergedRanges.length === 0) {
            mergedRanges.push({start: x, end: x.clone().add(1, "h")})
        } else if (mergedRanges[mergedRanges.length - 1].end.isSame(x)) {
            mergedRanges[mergedRanges.length -1].end.add(1, "h");
        } else {
            mergedRanges.push({start: x, end: x.clone().add(1, "h")});
        }
    });
    return (<div>
        <div>{roomName}</div>
        <div>
        {mergedRanges.map(c => 
            <div key={c.start.format()} className="flex">
                <div className="flex-1">
                    {c.start.format("DD MMMM HH:mm")} - {c.end.format("HH:mm")}
                </div>
            </div> 
            )} 
        </div>
    </div>)
}