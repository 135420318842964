import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderModel } from "./OrderModel";

const initialOrder:OrderModel = {
    OrderId: "",
    OrderDispalyId: 0,
    User: {Email: "", Name: "", Phonenumber: ""},
    RoomReservations: {},
    IsPaid: false,
    IsExpired: false,
    OrderTotal: 0,
    ExpireFromNowSeconds: 0,
    PaymentData: {ProviderType: "", Data: "", Signature: "", PayUrl: ""},
    GainedLoyaltyPoints: 0,
    IsEligibleForAccuringLoyaltyPoints: false,
};

export const OrderSlice = createSlice({
    name: "order",
    initialState: initialOrder,
    reducers: {
        setOrder: (state: OrderModel, action:PayloadAction<OrderModel>) => {
            Object.assign(state, action.payload);
            return state;
        }
    }
});