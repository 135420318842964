import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import axios from 'axios';
import { ServerConfig } from '../../utility/ServerConfig';
import { useDispatch, useSelector } from 'react-redux';
import { OrderSlice } from '../../store/slices/OrderSlice';
import { OrderModel } from '../../store/slices/OrderSlice/OrderModel';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { RoomDetails } from './components/RoomDetails';
import { OrderStatus } from './components/OrderStatus/OrderStatus';
import { CalendarHeader } from '../BookingPage/components/calendarHeader';
import TagManager from 'react-gtm-module';
import moment from 'moment';
import 'moment-duration-format';
import styles from './index.module.scss';
import { LoginForPoints } from '../../components/login-for-points';

const calculateLocalExpireAt = (secondsFromNow: number) => {
    return moment().add(secondsFromNow, "seconds");
}

const OrderPage = () => {
    let { orderId } = useRouteMatch<{ orderId: string }>().params;
    const dispatch = useDispatch();
    const isLqb = window.location.hostname === "booking.lightqb.com";
    const { t } = useTranslation();
    const order = useSelector((state: RootState) => state.order);
    const company = useSelector((state: RootState) => state.company);
    const [secondsToExpireLeft, setSecondsToExpireLeft] = useState(order.ExpireFromNowSeconds);
    const [localExpireAt, setLocalExpireAt] = useState(calculateLocalExpireAt(order.ExpireFromNowSeconds));
    const { Data, Signature } = order.PaymentData;
    const [companyLoyalties, setCompanyLoyalties] = useState<any>({});
    const userAccount = useSelector((state: RootState) => state.userAccount);

    const loadOrder = () => {
        axios.get<OrderModel>(`${ServerConfig.baseUrl}/api/orders/${orderId}`)
            .then(response => {
                dispatch(OrderSlice.actions.setOrder(response.data));

                if (response.data.IsPaid) {
                    const args = {
                        dataLayer: {
                            event: "order_confirmed",
                            'currency': 'UAH',
                            'transaction_id': orderId,
                            'amount': response.data.OrderTotal
                        },
                    };
                    TagManager.dataLayer(args);
                } else {
                    const args = {
                        dataLayer: {
                            event: "order_placed",
                            'currency': 'UAH',
                            'transaction_id': orderId,
                            'amount': response.data.OrderTotal
                        },
                    };
                    TagManager.dataLayer(args);
                }
            });
    }
    useEffect(() => {
        loadOrder();
    }, [userAccount]);

    useEffect(() => {
        setSecondsToExpireLeft(order.ExpireFromNowSeconds);
        setLocalExpireAt(calculateLocalExpireAt(order.ExpireFromNowSeconds));
    }, [order]);

    useMemo(() => {
        const secondsLeft = localExpireAt.diff(moment(), "seconds");
        if (secondsLeft > 0) {
            const timer = setTimeout(() => {
                setSecondsToExpireLeft(localExpireAt.diff(moment(), "seconds"));
            }, 1000);
            // Clear timeout if the component is unmounted
            return () => clearTimeout(timer);
        }

        if (order.ExpireFromNowSeconds > 0 && secondsLeft <= 0) {
            setTimeout(loadOrder, 2000);
        }
    }, [secondsToExpireLeft]);

    useEffect(() => {
        axios.get(`${ServerConfig.baseUrl}/api/loyalties`)
            .then(response => {
                setCompanyLoyalties(response.data);
            })
    }, []);

    const roomsAr = Object.keys(order.RoomReservations);
    return (
        <div>

            <CalendarHeader isLoading={false} />
            <div className="px-2">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                    <div>
                        <h1 className="text-2xl">{t("OrderDetails")}</h1>
                    </div>

                </div>
                <OrderStatus order={order} />
                <div className="border-1 py-2">
                    <h3 className="text-lg">{t("Rooms Details")}</h3>
                    {roomsAr.map(x => <RoomDetails key={x} roomName={x} room={order.RoomReservations[x]} />)}
                </div>
                <div className="border-1 py-2">
                    <h3 className="text-lg">{t("User details")}</h3>

                    <div>
                        <div>
                            <span>{t("ContactName")}:</span> <span>{order.User.Name}</span>
                        </div>
                        <div>
                            <span>{t("Email")}:</span> <span>{order.User.Email}</span>
                        </div>
                        <div>
                            <span>{t("Phonenumber")}:</span> <span>{order.User.Phonenumber}</span>
                        </div>
                    </div>
                </div>

                {!order.IsPaid && !order.IsExpired && secondsToExpireLeft > 0 && <div className="border-1 py-2">
                    <h3 className="text-lg">{t("Payment details")}</h3>
                    <div>

                        <div className="mb-2">
                            <span>{t("OrderTotal")}:</span> <span>{order.OrderTotal.toFixed(2)} {t("UAH")}</span>
                        </div>
                        <div>
                            {order.PaymentData.ProviderType === "Liqpay" && <form method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8">
                                <input type="hidden" name="data" value={Data} />
                                <input type="hidden" name="signature" value={Signature} />
                                <input type="image" alt="Liqpay button" src="//static.liqpay.ua/buttons/p1ru.radius.png" />
                            </form>}
                            {order.PaymentData.ProviderType === "Monobank" && <a href={order.PaymentData.PayUrl} className={`${styles.monoPaymentButton} inline-block`}>
                                {t("PayWithMonobank")}
                            </a>}

                        </div>
                        <div className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3" role="alert">
                            <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" /></svg>
                            <p>{t("InfoOrderAutoCancelIn", { ExpireInMinutes: moment.duration(secondsToExpireLeft, "seconds").format() })}</p>
                        </div>
                    </div>
                </div>}

                {order.GainedLoyaltyPoints > 0 && <div style={{ marginBottom: 16 }}>
                    <h1 className="font-semibold">
                        {t("PointsAccured", { amount: order.GainedLoyaltyPoints.toFixed(2) })}
                    </h1>
                </div>}
                

                {company?.HasActiveLoyalty && order.IsPaid 
                && order.IsEligibleForAccuringLoyaltyPoints 
                && !userAccount.Authenticated && userAccount.IsLoaded && <>
                    <LoginForPoints companyLoyalties={companyLoyalties} total={order.OrderTotal} />
                </>} 

                {order.IsPaid && isLqb &&
                    <div>
                        <h3 className="text-lg">{t("RouteDetails")}</h3>
                        <div>
                            <a target="_blank" rel="noreferrer" href={company?.GMapsLink}
                                className="hover:underline text-blue-700">{t("WeOnMap")}</a>
                        </div>
                        <div>
                            <h4 className="text-base">{t("Schema")}</h4>
                            <a target="_blank"
                                className="align-top inline-block p-1"
                                rel="noreferrer"
                                href="https://planeronlineprd.blob.core.windows.net/public/lightqb/route-schema/01.jpg">
                                <img
                                    style={{ maxWidth: "300px" }}
                                    alt="route schema 1"
                                    src="https://planeronlineprd.blob.core.windows.net/public/lightqb/route-schema/01.jpg"></img>
                            </a>
                            <a target="_blank"
                                className="align-top inline-block p-1"
                                rel="noreferrer"
                                href="https://planeronlineprd.blob.core.windows.net/public/lightqb/route-schema/02.jpg">
                                <img
                                    style={{ maxWidth: "300px" }}
                                    alt="route schema 2"
                                    src="https://planeronlineprd.blob.core.windows.net/public/lightqb/route-schema/02.jpg"></img>
                            </a>
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default OrderPage;